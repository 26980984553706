const maxStockResaleImages = 5;
$('#stock_resale_images').on('change',function(e){
  var files = e.target.files;
  var d = (new $.Deferred()).resolve();
  $.each(files,function(i,file){
    d = d.then(function() {
        return Uploader.upload(file)})
      .then(function(data){
        return previewImage(file, data.image_id);
    });
  });

  // 2枚の状態から画像を追加すると追加ボタンを非表示
  if ( maxStockResaleImages == ($('.image-box').length + 1) ) {
    $('#stock_resale_images').hide();
  }
  // 通信状態が悪い場合、追加できてしまうパターンがあるので、アラートだけ用意
  if ( maxStockResaleImages <= $('.image-box').length ) {
    alert(`添付できるのは ${maxStockResaleImages} 枚までです` );
    return false;
  }

  $('#stock_resale_images').val('');
});

$('.images-field').on('click','.btn-edit', function(e){
  e.preventDefault();
  $(this).parent().find('.edit-image-file-input').trigger("click");
});

$('.images-field').on('change','.edit-image-file-input', function(e){
  var file = e.target.files[0];
  var image_box = $(this).parent();
  Uploader.upload(file).done(function(data){
    replaceImage(file, data.image_id, image_box);
  });
});

$('.images-field').on('click','.btn-delete', function(e){
  e.preventDefault();
  $(this).parent().remove();
  // 画像削除したら画像追加ボタンを再表示
  if ( maxStockResaleImages > $('.image-box').length ) {
    $('#stock_resale_images').show();
  }
});

var replaceImage = function(imageFile, image_id, element){
  var reader = new FileReader();
  var img = element.find('img');
  var input = element.find('.stock-resale-images-input');
  var filename = element.find('p');
  reader.onload = function(e){
    input.attr({value: image_id});
    filename.html(imageFile.name);
    img.attr("src", e.target.result);
  };
  reader.readAsDataURL(imageFile);
}

var previewImage = function(imageFile, image_id){
  var reader = new FileReader();
  var img = new Image();
  var def =$.Deferred();
  reader.onload = function(e){
    var image_box = $('<div>',{class: 'image-box'});
    image_box.append(img);
    image_box.append($('<input>').attr({
          name: "stock_resale[images][]",
          value: image_id,
          style: "display: none;",
          type: "hidden",
          class: "stock-resale-images-input"}));
    image_box.append('<a href="" class= "btn-edit">編集</a>');
    image_box.append($('<input>').attr({
          name: "edit-image[]",
          style: "display: none;",
          type: "file",
          class: "edit-image-file-input file-input"}));
    image_box.append('<a href="" class="btn-delete">削除</a>');
    $('.image-box-wrap').append(image_box);
    img.src = e.target.result;
    img.setAttribute('class', 'stock_resales_image');
    def.resolve();
  };
  reader.readAsDataURL(imageFile);
  return def.promise();
}

var Uploader = {
  upload: function(imageFile){
    var def =$.Deferred();
    var formData = new FormData();
    var stockId = $('#stock_resale_images').data('stock-id');
    formData.append('image', imageFile);
    formData.append('authenticity_token', $('meta[name="csrf-token"]').attr('content'));
    $.ajax({
      type: "POST",
      url: '/stocks/' + stockId + '/stock_resales/upload_image',
      data: formData,
      dataType: 'json',
      processData: false,
      contentType: false,
      success: def.resolve
    })
    return def.promise();
  }
}
