// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import $ from "jquery";
window.jQuery = $;
window.$ = $;

import 'popper.js';
import "bootstrap";
import "fastclick";
import "nprogress";
import "datatables-bootstrap";

var DataTable = require('datatables.net');
require('datatables.net-bs');
require('datatables.net-buttons');
require('datatables.net-buttons-bs');
require('datatables.net-fixedheader');
require('datatables.net-keytable');
require('datatables.net-responsive');
require('datatables.net-responsive-bs');
require('datatables.net-scroller');

$.fn.dataTable = DataTable;
$.fn.dataTableSettings = DataTable.settings;
$.fn.dataTableExt = DataTable.ext;
DataTable.$ = $;

$.fn.DataTable = function ( opts ) {
    return $(this).dataTable( opts ).api();
};

require("dashboard/dataTables.bootstrap.min");

window.moment = require('moment');
window.moment.locale('ja');

require("dashboard/daterangepicker.js");
require("dashboard/bootstrap-datetimepicker.min");
require("dashboard/custom.js");
require("dashboard/report-stock.js");
require("dashboard/stock.js");
require("dashboard/transport.js");
require("dashboard/product.js");
require("dashboard/product-form.js");
require("dashboard/inventory.js");
require("dashboard/stock-resale-form.js");

import "datatables.net-bs/css/dataTables.bootstrap.css";
import "datatables.net-buttons-bs/css/buttons.bootstrap.css";
import "datatables.net-responsive-bs/css/responsive.bootstrap.css";
import "daterangepicker/daterangepicker.css";
import "../stylesheets/application";

require("@nathanvda/cocoon");
require("bootstrap-select");


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
